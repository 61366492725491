import React from 'react'
import HeaderPT from '../Components/HeaderPT'
// import '../css/HeaderCss.css'
// import 'bootstrap/dist/css/bootstrap.min.css';

export default function AboutPT() {
  return (
    <div className='mainDiv'>
      <HeaderPT />
      test about pt
    </div>
  )
}

