import React from 'react'
import HeaderEn from '../Components/HeaderEn'

export default function AboutEn() {
  return (
    <div>
      <HeaderEn />
      <br/>
      Who am I?

      About me

      My name is Luan Vitor, I'm 21 and now a days living in Sao Paulo, Brazil.

      I'm looking for new experiences as a Full Stak Developer,

      I'm a fully love of tecnologies,

      Proactive,
      Capacity to things by my self,
      Not afraid to learn from my mistakes,
    </div>
  )
}
